// Generated from /DSL/DSL.Library/graph.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';


const serializedATN = [4,0,114,995,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,
2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,
7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,
19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,
2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,
34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,
7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,
48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,55,7,55,
2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,61,2,62,7,62,2,
63,7,63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,
7,70,2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,
77,2,78,7,78,2,79,7,79,2,80,7,80,2,81,7,81,2,82,7,82,2,83,7,83,2,84,7,84,
2,85,7,85,2,86,7,86,2,87,7,87,2,88,7,88,2,89,7,89,2,90,7,90,2,91,7,91,2,
92,7,92,2,93,7,93,2,94,7,94,2,95,7,95,2,96,7,96,2,97,7,97,2,98,7,98,2,99,
7,99,2,100,7,100,2,101,7,101,2,102,7,102,2,103,7,103,2,104,7,104,2,105,7,
105,2,106,7,106,2,107,7,107,2,108,7,108,2,109,7,109,2,110,7,110,2,111,7,
111,2,112,7,112,2,113,7,113,2,114,7,114,2,115,7,115,2,116,7,116,2,117,7,
117,2,118,7,118,2,119,7,119,2,120,7,120,2,121,7,121,2,122,7,122,2,123,7,
123,2,124,7,124,1,0,1,0,1,0,1,0,1,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,
1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,4,1,4,1,4,
1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,5,1,5,1,6,1,6,5,6,298,8,6,10,6,
12,6,301,9,6,1,6,1,6,1,6,1,6,5,6,307,8,6,10,6,12,6,310,9,6,1,6,1,6,3,6,314,
8,6,1,7,1,7,3,7,318,8,7,1,8,1,8,3,8,322,8,8,1,9,1,9,1,9,1,10,1,10,1,10,1,
11,1,11,1,12,1,12,1,13,1,13,1,14,1,14,1,15,1,15,1,16,1,16,1,16,1,16,1,16,
1,16,1,17,1,17,1,17,1,17,1,17,1,18,1,18,1,18,1,19,1,19,1,19,1,19,1,19,1,
19,1,20,1,20,1,20,1,20,1,20,1,20,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,
1,21,1,21,1,22,1,22,1,22,1,22,1,22,1,22,1,23,1,23,1,23,1,23,1,23,1,23,1,
23,1,23,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,25,1,25,
1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,26,1,26,1,26,1,26,1,26,1,26,1,
26,1,26,1,27,1,27,1,27,1,27,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,29,
1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,
30,1,30,1,30,1,30,1,31,1,31,1,31,1,31,1,31,1,31,1,31,1,31,1,31,1,31,1,31,
1,31,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,33,1,33,1,33,1,34,1,34,1,
34,1,34,1,34,1,35,1,35,1,35,1,35,1,35,1,36,1,36,1,36,1,36,1,36,1,36,1,36,
1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,38,1,38,1,38,1,38,1,38,1,
39,1,39,1,39,1,39,1,39,1,39,1,39,1,39,1,40,1,40,1,40,1,40,1,41,1,41,1,41,
1,41,1,41,1,41,1,41,1,41,1,41,1,42,1,42,1,42,1,42,1,42,1,42,1,42,1,43,1,
43,1,43,1,43,1,43,1,44,1,44,1,44,1,45,1,45,1,45,1,45,1,45,1,45,1,45,1,46,
1,46,1,46,1,47,1,47,1,47,1,47,1,47,1,47,1,48,1,48,1,48,1,49,1,49,1,49,1,
49,1,49,1,49,1,49,1,49,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,51,1,51,
1,51,1,51,1,51,1,52,1,52,1,52,1,52,1,53,1,53,1,53,1,54,1,54,1,54,1,55,1,
55,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,56,1,56,1,56,1,56,
1,56,1,56,1,56,1,57,1,57,1,57,1,57,1,57,1,57,1,57,1,58,1,58,1,58,1,58,1,
58,1,58,1,58,1,58,1,58,1,58,1,58,1,58,1,58,1,59,1,59,1,59,1,59,1,59,1,59,
1,59,1,59,1,59,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,61,1,61,1,61,1,61,1,
61,1,62,1,62,1,62,1,62,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,64,1,64,1,64,
1,64,1,64,1,64,1,65,1,65,1,65,1,65,1,65,1,65,1,65,1,66,1,66,1,66,1,66,1,
66,1,67,1,67,1,67,1,67,1,67,1,67,1,67,1,68,1,68,1,68,1,68,1,68,1,68,1,68,
1,68,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,70,1,
70,1,70,1,70,1,70,1,71,1,71,1,71,1,71,1,72,1,72,1,72,1,72,1,72,1,73,1,73,
1,73,1,73,1,74,1,74,1,74,1,74,1,74,1,75,1,75,1,75,1,75,1,75,1,76,1,76,1,
76,1,76,1,76,1,77,1,77,1,78,1,78,1,79,3,79,744,8,79,1,79,1,79,1,80,3,80,
749,8,80,1,80,1,80,1,81,1,81,1,81,5,81,756,8,81,10,81,12,81,759,9,81,1,81,
3,81,762,8,81,1,81,1,81,4,81,766,8,81,11,81,12,81,767,1,81,3,81,771,8,81,
1,81,1,81,1,81,3,81,776,8,81,1,82,1,82,1,82,5,82,781,8,82,10,82,12,82,784,
9,82,3,82,786,8,82,1,83,1,83,3,83,790,8,83,1,83,4,83,793,8,83,11,83,12,83,
794,1,84,1,84,1,84,1,84,1,84,1,84,1,84,1,84,1,84,3,84,806,8,84,1,85,1,85,
1,85,1,85,1,85,1,85,1,86,1,86,1,86,1,86,1,86,1,86,1,86,1,87,1,87,1,87,1,
87,1,87,1,87,1,87,1,87,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,89,1,89,1,89,
1,89,1,89,1,89,1,89,1,89,1,90,1,90,1,90,1,90,1,90,1,91,1,91,1,92,1,92,1,
93,1,93,1,94,1,94,1,95,1,95,1,96,1,96,1,97,1,97,1,98,1,98,1,99,1,99,1,100,
1,100,1,101,1,101,1,102,1,102,1,103,1,103,1,104,1,104,1,105,1,105,1,106,
1,106,1,107,1,107,1,107,1,108,1,108,1,108,1,109,1,109,1,109,1,110,1,110,
1,110,1,111,1,111,1,111,1,112,1,112,1,112,1,113,1,113,1,113,1,113,3,113,
903,8,113,1,114,1,114,1,115,1,115,1,115,1,115,1,115,3,115,912,8,115,1,116,
1,116,3,116,916,8,116,1,117,3,117,919,8,117,1,117,1,117,5,117,923,8,117,
10,117,12,117,926,9,117,1,117,3,117,929,8,117,1,117,1,117,1,117,5,117,934,
8,117,10,117,12,117,937,9,117,3,117,939,8,117,1,118,1,118,1,119,1,119,1,
120,1,120,3,120,947,8,120,1,121,1,121,1,121,1,121,5,121,953,8,121,10,121,
12,121,956,9,121,1,121,1,121,1,122,1,122,1,122,1,122,1,122,5,122,965,8,122,
10,122,12,122,968,9,122,1,122,1,122,1,122,1,122,1,122,1,123,1,123,1,123,
1,123,5,123,979,8,123,10,123,12,123,982,9,123,1,123,1,123,1,123,1,123,1,
123,1,124,4,124,990,8,124,11,124,12,124,991,1,124,1,124,2,966,980,0,125,
1,1,3,2,5,3,7,4,9,5,11,0,13,6,15,0,17,0,19,0,21,0,23,7,25,8,27,9,29,10,31,
11,33,12,35,13,37,14,39,15,41,16,43,17,45,18,47,19,49,20,51,21,53,22,55,
23,57,24,59,25,61,26,63,27,65,28,67,29,69,30,71,31,73,32,75,33,77,34,79,
35,81,36,83,37,85,38,87,39,89,40,91,41,93,42,95,43,97,44,99,45,101,46,103,
47,105,48,107,49,109,50,111,51,113,52,115,53,117,54,119,55,121,56,123,57,
125,58,127,59,129,60,131,61,133,62,135,63,137,64,139,65,141,66,143,67,145,
68,147,69,149,70,151,71,153,72,155,73,157,74,159,75,161,76,163,0,165,0,167,
0,169,77,171,78,173,79,175,80,177,81,179,82,181,83,183,84,185,85,187,86,
189,87,191,88,193,89,195,90,197,91,199,92,201,93,203,94,205,95,207,96,209,
97,211,98,213,99,215,100,217,101,219,102,221,103,223,104,225,105,227,106,
229,107,231,108,233,109,235,110,237,0,239,0,241,0,243,111,245,112,247,113,
249,114,1,0,10,3,0,0,31,34,34,92,92,1,0,96,96,8,0,34,34,39,39,92,92,98,98,
102,102,110,110,114,114,116,116,1,0,48,57,1,0,49,57,2,0,69,69,101,101,2,
0,43,43,45,45,3,0,48,57,65,90,97,122,2,0,10,10,13,13,3,0,9,10,13,13,32,32,
1014,0,1,1,0,0,0,0,3,1,0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,13,1,
0,0,0,0,23,1,0,0,0,0,25,1,0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,
33,1,0,0,0,0,35,1,0,0,0,0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,
0,0,0,45,1,0,0,0,0,47,1,0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,0,53,1,0,0,0,0,55,
1,0,0,0,0,57,1,0,0,0,0,59,1,0,0,0,0,61,1,0,0,0,0,63,1,0,0,0,0,65,1,0,0,0,
0,67,1,0,0,0,0,69,1,0,0,0,0,71,1,0,0,0,0,73,1,0,0,0,0,75,1,0,0,0,0,77,1,
0,0,0,0,79,1,0,0,0,0,81,1,0,0,0,0,83,1,0,0,0,0,85,1,0,0,0,0,87,1,0,0,0,0,
89,1,0,0,0,0,91,1,0,0,0,0,93,1,0,0,0,0,95,1,0,0,0,0,97,1,0,0,0,0,99,1,0,
0,0,0,101,1,0,0,0,0,103,1,0,0,0,0,105,1,0,0,0,0,107,1,0,0,0,0,109,1,0,0,
0,0,111,1,0,0,0,0,113,1,0,0,0,0,115,1,0,0,0,0,117,1,0,0,0,0,119,1,0,0,0,
0,121,1,0,0,0,0,123,1,0,0,0,0,125,1,0,0,0,0,127,1,0,0,0,0,129,1,0,0,0,0,
131,1,0,0,0,0,133,1,0,0,0,0,135,1,0,0,0,0,137,1,0,0,0,0,139,1,0,0,0,0,141,
1,0,0,0,0,143,1,0,0,0,0,145,1,0,0,0,0,147,1,0,0,0,0,149,1,0,0,0,0,151,1,
0,0,0,0,153,1,0,0,0,0,155,1,0,0,0,0,157,1,0,0,0,0,159,1,0,0,0,0,161,1,0,
0,0,0,169,1,0,0,0,0,171,1,0,0,0,0,173,1,0,0,0,0,175,1,0,0,0,0,177,1,0,0,
0,0,179,1,0,0,0,0,181,1,0,0,0,0,183,1,0,0,0,0,185,1,0,0,0,0,187,1,0,0,0,
0,189,1,0,0,0,0,191,1,0,0,0,0,193,1,0,0,0,0,195,1,0,0,0,0,197,1,0,0,0,0,
199,1,0,0,0,0,201,1,0,0,0,0,203,1,0,0,0,0,205,1,0,0,0,0,207,1,0,0,0,0,209,
1,0,0,0,0,211,1,0,0,0,0,213,1,0,0,0,0,215,1,0,0,0,0,217,1,0,0,0,0,219,1,
0,0,0,0,221,1,0,0,0,0,223,1,0,0,0,0,225,1,0,0,0,0,227,1,0,0,0,0,229,1,0,
0,0,0,231,1,0,0,0,0,233,1,0,0,0,0,235,1,0,0,0,0,243,1,0,0,0,0,245,1,0,0,
0,0,247,1,0,0,0,0,249,1,0,0,0,1,251,1,0,0,0,3,256,1,0,0,0,5,265,1,0,0,0,
7,273,1,0,0,0,9,280,1,0,0,0,11,293,1,0,0,0,13,313,1,0,0,0,15,317,1,0,0,0,
17,321,1,0,0,0,19,323,1,0,0,0,21,326,1,0,0,0,23,329,1,0,0,0,25,331,1,0,0,
0,27,333,1,0,0,0,29,335,1,0,0,0,31,337,1,0,0,0,33,339,1,0,0,0,35,345,1,0,
0,0,37,350,1,0,0,0,39,353,1,0,0,0,41,359,1,0,0,0,43,365,1,0,0,0,45,375,1,
0,0,0,47,381,1,0,0,0,49,389,1,0,0,0,51,400,1,0,0,0,53,410,1,0,0,0,55,418,
1,0,0,0,57,422,1,0,0,0,59,430,1,0,0,0,61,438,1,0,0,0,63,449,1,0,0,0,65,461,
1,0,0,0,67,469,1,0,0,0,69,472,1,0,0,0,71,477,1,0,0,0,73,482,1,0,0,0,75,489,
1,0,0,0,77,498,1,0,0,0,79,503,1,0,0,0,81,511,1,0,0,0,83,515,1,0,0,0,85,524,
1,0,0,0,87,531,1,0,0,0,89,536,1,0,0,0,91,539,1,0,0,0,93,546,1,0,0,0,95,549,
1,0,0,0,97,555,1,0,0,0,99,558,1,0,0,0,101,566,1,0,0,0,103,574,1,0,0,0,105,
579,1,0,0,0,107,583,1,0,0,0,109,586,1,0,0,0,111,589,1,0,0,0,113,601,1,0,
0,0,115,608,1,0,0,0,117,615,1,0,0,0,119,628,1,0,0,0,121,637,1,0,0,0,123,
644,1,0,0,0,125,649,1,0,0,0,127,653,1,0,0,0,129,660,1,0,0,0,131,666,1,0,
0,0,133,673,1,0,0,0,135,678,1,0,0,0,137,685,1,0,0,0,139,693,1,0,0,0,141,
705,1,0,0,0,143,710,1,0,0,0,145,714,1,0,0,0,147,719,1,0,0,0,149,723,1,0,
0,0,151,728,1,0,0,0,153,733,1,0,0,0,155,738,1,0,0,0,157,740,1,0,0,0,159,
743,1,0,0,0,161,748,1,0,0,0,163,775,1,0,0,0,165,785,1,0,0,0,167,787,1,0,
0,0,169,805,1,0,0,0,171,807,1,0,0,0,173,813,1,0,0,0,175,820,1,0,0,0,177,
828,1,0,0,0,179,835,1,0,0,0,181,843,1,0,0,0,183,848,1,0,0,0,185,850,1,0,
0,0,187,852,1,0,0,0,189,854,1,0,0,0,191,856,1,0,0,0,193,858,1,0,0,0,195,
860,1,0,0,0,197,862,1,0,0,0,199,864,1,0,0,0,201,866,1,0,0,0,203,868,1,0,
0,0,205,870,1,0,0,0,207,872,1,0,0,0,209,874,1,0,0,0,211,876,1,0,0,0,213,
878,1,0,0,0,215,880,1,0,0,0,217,883,1,0,0,0,219,886,1,0,0,0,221,889,1,0,
0,0,223,892,1,0,0,0,225,895,1,0,0,0,227,902,1,0,0,0,229,904,1,0,0,0,231,
911,1,0,0,0,233,915,1,0,0,0,235,938,1,0,0,0,237,940,1,0,0,0,239,942,1,0,
0,0,241,946,1,0,0,0,243,948,1,0,0,0,245,959,1,0,0,0,247,974,1,0,0,0,249,
989,1,0,0,0,251,252,5,102,0,0,252,253,5,114,0,0,253,254,5,111,0,0,254,255,
5,109,0,0,255,2,1,0,0,0,256,257,5,115,0,0,257,258,5,116,0,0,258,259,5,97,
0,0,259,260,5,114,0,0,260,261,5,116,0,0,261,262,5,105,0,0,262,263,5,110,
0,0,263,264,5,103,0,0,264,4,1,0,0,0,265,266,5,101,0,0,266,267,5,120,0,0,
267,268,5,105,0,0,268,269,5,116,0,0,269,270,5,105,0,0,270,271,5,110,0,0,
271,272,5,103,0,0,272,6,1,0,0,0,273,274,5,111,0,0,274,275,5,112,0,0,275,
276,5,101,0,0,276,277,5,110,0,0,277,278,5,101,0,0,278,279,5,100,0,0,279,
8,1,0,0,0,280,281,5,98,0,0,281,282,5,108,0,0,282,283,5,111,0,0,283,284,5,
99,0,0,284,285,5,107,0,0,285,286,5,77,0,0,286,287,5,101,0,0,287,288,5,115,
0,0,288,289,5,115,0,0,289,290,5,97,0,0,290,291,5,103,0,0,291,292,5,101,0,
0,292,10,1,0,0,0,293,294,8,0,0,0,294,12,1,0,0,0,295,299,3,201,100,0,296,
298,3,17,8,0,297,296,1,0,0,0,298,301,1,0,0,0,299,297,1,0,0,0,299,300,1,0,
0,0,300,302,1,0,0,0,301,299,1,0,0,0,302,303,3,201,100,0,303,314,1,0,0,0,
304,308,3,199,99,0,305,307,3,15,7,0,306,305,1,0,0,0,307,310,1,0,0,0,308,
306,1,0,0,0,308,309,1,0,0,0,309,311,1,0,0,0,310,308,1,0,0,0,311,312,3,199,
99,0,312,314,1,0,0,0,313,295,1,0,0,0,313,304,1,0,0,0,314,14,1,0,0,0,315,
318,3,11,5,0,316,318,3,21,10,0,317,315,1,0,0,0,317,316,1,0,0,0,318,16,1,
0,0,0,319,322,8,1,0,0,320,322,3,19,9,0,321,319,1,0,0,0,321,320,1,0,0,0,322,
18,1,0,0,0,323,324,5,92,0,0,324,325,7,1,0,0,325,20,1,0,0,0,326,327,5,92,
0,0,327,328,7,2,0,0,328,22,1,0,0,0,329,330,5,43,0,0,330,24,1,0,0,0,331,332,
5,45,0,0,332,26,1,0,0,0,333,334,5,42,0,0,334,28,1,0,0,0,335,336,5,47,0,0,
336,30,1,0,0,0,337,338,5,37,0,0,338,32,1,0,0,0,339,340,5,97,0,0,340,341,
5,112,0,0,341,342,5,112,0,0,342,343,5,108,0,0,343,344,5,121,0,0,344,34,1,
0,0,0,345,346,5,119,0,0,346,347,5,105,0,0,347,348,5,116,0,0,348,349,5,104,
0,0,349,36,1,0,0,0,350,351,5,97,0,0,351,352,5,115,0,0,352,38,1,0,0,0,353,
354,5,97,0,0,354,355,5,115,0,0,355,356,5,121,0,0,356,357,5,110,0,0,357,358,
5,99,0,0,358,40,1,0,0,0,359,360,5,98,0,0,360,361,5,108,0,0,361,362,5,111,
0,0,362,363,5,99,0,0,363,364,5,107,0,0,364,42,1,0,0,0,365,366,5,98,0,0,366,
367,5,108,0,0,367,368,5,111,0,0,368,369,5,99,0,0,369,370,5,107,0,0,370,371,
5,99,0,0,371,372,5,97,0,0,372,373,5,108,0,0,373,374,5,108,0,0,374,44,1,0,
0,0,375,376,5,99,0,0,376,377,5,97,0,0,377,378,5,116,0,0,378,379,5,99,0,0,
379,380,5,104,0,0,380,46,1,0,0,0,381,382,5,99,0,0,382,383,5,111,0,0,383,
384,5,109,0,0,384,385,5,109,0,0,385,386,5,101,0,0,386,387,5,110,0,0,387,
388,5,116,0,0,388,48,1,0,0,0,389,390,5,99,0,0,390,391,5,111,0,0,391,392,
5,110,0,0,392,393,5,100,0,0,393,394,5,105,0,0,394,395,5,116,0,0,395,396,
5,105,0,0,396,397,5,111,0,0,397,398,5,110,0,0,398,399,5,115,0,0,399,50,1,
0,0,0,400,401,5,99,0,0,401,402,5,111,0,0,402,403,5,110,0,0,403,404,5,102,
0,0,404,405,5,105,0,0,405,406,5,100,0,0,406,407,5,101,0,0,407,408,5,110,
0,0,408,409,5,116,0,0,409,52,1,0,0,0,410,411,5,99,0,0,411,412,5,111,0,0,
412,413,5,110,0,0,413,414,5,116,0,0,414,415,5,101,0,0,415,416,5,120,0,0,
416,417,5,116,0,0,417,54,1,0,0,0,418,419,5,99,0,0,419,420,5,111,0,0,420,
421,5,119,0,0,421,56,1,0,0,0,422,423,5,100,0,0,423,424,5,101,0,0,424,425,
5,102,0,0,425,426,5,97,0,0,426,427,5,117,0,0,427,428,5,108,0,0,428,429,5,
116,0,0,429,58,1,0,0,0,430,431,5,100,0,0,431,432,5,101,0,0,432,433,5,102,
0,0,433,434,5,105,0,0,434,435,5,110,0,0,435,436,5,101,0,0,436,437,5,100,
0,0,437,60,1,0,0,0,438,439,5,100,0,0,439,440,5,105,0,0,440,441,5,103,0,0,
441,442,5,114,0,0,442,443,5,101,0,0,443,444,5,115,0,0,444,445,5,115,0,0,
445,446,5,105,0,0,446,447,5,111,0,0,447,448,5,110,0,0,448,62,1,0,0,0,449,
450,5,100,0,0,450,451,5,105,0,0,451,452,5,103,0,0,452,453,5,114,0,0,453,
454,5,101,0,0,454,455,5,115,0,0,455,456,5,115,0,0,456,457,5,105,0,0,457,
458,5,111,0,0,458,459,5,110,0,0,459,460,5,115,0,0,460,64,1,0,0,0,461,462,
5,100,0,0,462,463,5,105,0,0,463,464,5,115,0,0,464,465,5,97,0,0,465,466,5,
98,0,0,466,467,5,108,0,0,467,468,5,101,0,0,468,66,1,0,0,0,469,470,5,100,
0,0,470,471,5,111,0,0,471,68,1,0,0,0,472,473,5,100,0,0,473,474,5,111,0,0,
474,475,5,110,0,0,475,476,5,101,0,0,476,70,1,0,0,0,477,478,5,101,0,0,478,
479,5,108,0,0,479,480,5,115,0,0,480,481,5,101,0,0,481,72,1,0,0,0,482,483,
5,101,0,0,483,484,5,110,0,0,484,485,5,97,0,0,485,486,5,98,0,0,486,487,5,
108,0,0,487,488,5,101,0,0,488,74,1,0,0,0,489,490,5,101,0,0,490,491,5,120,
0,0,491,492,5,116,0,0,492,493,5,101,0,0,493,494,5,114,0,0,494,495,5,110,
0,0,495,496,5,97,0,0,496,497,5,108,0,0,497,76,1,0,0,0,498,499,5,101,0,0,
499,500,5,120,0,0,500,501,5,105,0,0,501,502,5,116,0,0,502,78,1,0,0,0,503,
504,5,102,0,0,504,505,5,105,0,0,505,506,5,110,0,0,506,507,5,97,0,0,507,508,
5,108,0,0,508,509,5,108,0,0,509,510,5,121,0,0,510,80,1,0,0,0,511,512,5,102,
0,0,512,513,5,111,0,0,513,514,5,114,0,0,514,82,1,0,0,0,515,516,5,102,0,0,
516,517,5,117,0,0,517,518,5,110,0,0,518,519,5,99,0,0,519,520,5,116,0,0,520,
521,5,105,0,0,521,522,5,111,0,0,522,523,5,110,0,0,523,84,1,0,0,0,524,525,
5,103,0,0,525,526,5,108,0,0,526,527,5,111,0,0,527,528,5,98,0,0,528,529,5,
97,0,0,529,530,5,108,0,0,530,86,1,0,0,0,531,532,5,103,0,0,532,533,5,111,
0,0,533,534,5,116,0,0,534,535,5,111,0,0,535,88,1,0,0,0,536,537,5,105,0,0,
537,538,5,102,0,0,538,90,1,0,0,0,539,540,5,105,0,0,540,541,5,109,0,0,541,
542,5,112,0,0,542,543,5,111,0,0,543,544,5,114,0,0,544,545,5,116,0,0,545,
92,1,0,0,0,546,547,5,105,0,0,547,548,5,110,0,0,548,94,1,0,0,0,549,550,5,
105,0,0,550,551,5,110,0,0,551,552,5,112,0,0,552,553,5,117,0,0,553,554,5,
116,0,0,554,96,1,0,0,0,555,556,5,105,0,0,556,557,5,115,0,0,557,98,1,0,0,
0,558,559,5,108,0,0,559,560,5,105,0,0,560,561,5,98,0,0,561,562,5,114,0,0,
562,563,5,97,0,0,563,564,5,114,0,0,564,565,5,121,0,0,565,100,1,0,0,0,566,
567,5,108,0,0,567,568,5,111,0,0,568,569,5,103,0,0,569,570,5,101,0,0,570,
571,5,120,0,0,571,572,5,112,0,0,572,573,5,114,0,0,573,102,1,0,0,0,574,575,
5,110,0,0,575,576,5,111,0,0,576,577,5,100,0,0,577,578,5,101,0,0,578,104,
1,0,0,0,579,580,5,110,0,0,580,581,5,111,0,0,581,582,5,116,0,0,582,106,1,
0,0,0,583,584,5,111,0,0,584,585,5,102,0,0,585,108,1,0,0,0,586,587,5,111,
0,0,587,588,5,110,0,0,588,110,1,0,0,0,589,590,5,111,0,0,590,591,5,110,0,
0,591,592,5,100,0,0,592,593,5,105,0,0,593,594,5,103,0,0,594,595,5,114,0,
0,595,596,5,101,0,0,596,597,5,116,0,0,597,598,5,117,0,0,598,599,5,114,0,
0,599,600,5,110,0,0,600,112,1,0,0,0,601,602,5,111,0,0,602,603,5,110,0,0,
603,604,5,101,0,0,604,605,5,120,0,0,605,606,5,105,0,0,606,607,5,116,0,0,
607,114,1,0,0,0,608,609,5,111,0,0,609,610,5,117,0,0,610,611,5,116,0,0,611,
612,5,112,0,0,612,613,5,117,0,0,613,614,5,116,0,0,614,116,1,0,0,0,615,616,
5,112,0,0,616,617,5,114,0,0,617,618,5,101,0,0,618,619,5,112,0,0,619,620,
5,114,0,0,620,621,5,111,0,0,621,622,5,99,0,0,622,623,5,101,0,0,623,624,5,
115,0,0,624,625,5,115,0,0,625,626,5,111,0,0,626,627,5,114,0,0,627,118,1,
0,0,0,628,629,5,112,0,0,629,630,5,114,0,0,630,631,5,105,0,0,631,632,5,111,
0,0,632,633,5,114,0,0,633,634,5,105,0,0,634,635,5,116,0,0,635,636,5,121,
0,0,636,120,1,0,0,0,637,638,5,114,0,0,638,639,5,101,0,0,639,640,5,116,0,
0,640,641,5,117,0,0,641,642,5,114,0,0,642,643,5,110,0,0,643,122,1,0,0,0,
644,645,5,115,0,0,645,646,5,97,0,0,646,647,5,118,0,0,647,648,5,101,0,0,648,
124,1,0,0,0,649,650,5,115,0,0,650,651,5,101,0,0,651,652,5,116,0,0,652,126,
1,0,0,0,653,654,5,115,0,0,654,655,5,104,0,0,655,656,5,97,0,0,656,657,5,114,
0,0,657,658,5,101,0,0,658,659,5,100,0,0,659,128,1,0,0,0,660,661,5,115,0,
0,661,662,5,116,0,0,662,663,5,97,0,0,663,664,5,114,0,0,664,665,5,116,0,0,
665,130,1,0,0,0,666,667,5,115,0,0,667,668,5,116,0,0,668,669,5,97,0,0,669,
670,5,116,0,0,670,671,5,105,0,0,671,672,5,99,0,0,672,132,1,0,0,0,673,674,
5,115,0,0,674,675,5,121,0,0,675,676,5,110,0,0,676,677,5,99,0,0,677,134,1,
0,0,0,678,679,5,115,0,0,679,680,5,121,0,0,680,681,5,115,0,0,681,682,5,116,
0,0,682,683,5,101,0,0,683,684,5,109,0,0,684,136,1,0,0,0,685,686,5,116,0,
0,686,687,5,105,0,0,687,688,5,109,0,0,688,689,5,101,0,0,689,690,5,111,0,
0,690,691,5,117,0,0,691,692,5,116,0,0,692,138,1,0,0,0,693,694,5,116,0,0,
694,695,5,114,0,0,695,696,5,97,0,0,696,697,5,110,0,0,697,698,5,115,0,0,698,
699,5,105,0,0,699,700,5,116,0,0,700,701,5,105,0,0,701,702,5,111,0,0,702,
703,5,110,0,0,703,704,5,115,0,0,704,140,1,0,0,0,705,706,5,116,0,0,706,707,
5,97,0,0,707,708,5,103,0,0,708,709,5,115,0,0,709,142,1,0,0,0,710,711,5,116,
0,0,711,712,5,114,0,0,712,713,5,121,0,0,713,144,1,0,0,0,714,715,5,116,0,
0,715,716,5,121,0,0,716,717,5,112,0,0,717,718,5,101,0,0,718,146,1,0,0,0,
719,720,5,118,0,0,720,721,5,97,0,0,721,722,5,114,0,0,722,148,1,0,0,0,723,
724,5,119,0,0,724,725,5,97,0,0,725,726,5,105,0,0,726,727,5,116,0,0,727,150,
1,0,0,0,728,729,5,119,0,0,729,730,5,104,0,0,730,731,5,101,0,0,731,732,5,
110,0,0,732,152,1,0,0,0,733,734,5,102,0,0,734,735,5,105,0,0,735,736,5,108,
0,0,736,737,5,108,0,0,737,154,1,0,0,0,738,739,5,35,0,0,739,156,1,0,0,0,740,
741,5,36,0,0,741,158,1,0,0,0,742,744,3,25,12,0,743,742,1,0,0,0,743,744,1,
0,0,0,744,745,1,0,0,0,745,746,3,163,81,0,746,160,1,0,0,0,747,749,3,25,12,
0,748,747,1,0,0,0,748,749,1,0,0,0,749,750,1,0,0,0,750,751,3,165,82,0,751,
162,1,0,0,0,752,753,3,165,82,0,753,757,3,211,105,0,754,756,7,3,0,0,755,754,
1,0,0,0,756,759,1,0,0,0,757,755,1,0,0,0,757,758,1,0,0,0,758,761,1,0,0,0,
759,757,1,0,0,0,760,762,3,167,83,0,761,760,1,0,0,0,761,762,1,0,0,0,762,776,
1,0,0,0,763,765,3,211,105,0,764,766,7,3,0,0,765,764,1,0,0,0,766,767,1,0,
0,0,767,765,1,0,0,0,767,768,1,0,0,0,768,770,1,0,0,0,769,771,3,167,83,0,770,
769,1,0,0,0,770,771,1,0,0,0,771,776,1,0,0,0,772,773,3,165,82,0,773,774,3,
167,83,0,774,776,1,0,0,0,775,752,1,0,0,0,775,763,1,0,0,0,775,772,1,0,0,0,
776,164,1,0,0,0,777,786,5,48,0,0,778,782,7,4,0,0,779,781,7,3,0,0,780,779,
1,0,0,0,781,784,1,0,0,0,782,780,1,0,0,0,782,783,1,0,0,0,783,786,1,0,0,0,
784,782,1,0,0,0,785,777,1,0,0,0,785,778,1,0,0,0,786,166,1,0,0,0,787,789,
7,5,0,0,788,790,7,6,0,0,789,788,1,0,0,0,789,790,1,0,0,0,790,792,1,0,0,0,
791,793,7,3,0,0,792,791,1,0,0,0,793,794,1,0,0,0,794,792,1,0,0,0,794,795,
1,0,0,0,795,168,1,0,0,0,796,797,5,116,0,0,797,798,5,114,0,0,798,799,5,117,
0,0,799,806,5,101,0,0,800,801,5,102,0,0,801,802,5,97,0,0,802,803,5,108,0,
0,803,804,5,115,0,0,804,806,5,101,0,0,805,796,1,0,0,0,805,800,1,0,0,0,806,
170,1,0,0,0,807,808,5,101,0,0,808,809,5,109,0,0,809,810,5,112,0,0,810,811,
5,116,0,0,811,812,5,121,0,0,812,172,1,0,0,0,813,814,5,110,0,0,814,815,5,
117,0,0,815,816,5,109,0,0,816,817,5,98,0,0,817,818,5,101,0,0,818,819,5,114,
0,0,819,174,1,0,0,0,820,821,5,98,0,0,821,822,5,111,0,0,822,823,5,111,0,0,
823,824,5,108,0,0,824,825,5,101,0,0,825,826,5,97,0,0,826,827,5,110,0,0,827,
176,1,0,0,0,828,829,5,115,0,0,829,830,5,116,0,0,830,831,5,114,0,0,831,832,
5,105,0,0,832,833,5,110,0,0,833,834,5,103,0,0,834,178,1,0,0,0,835,836,5,
117,0,0,836,837,5,110,0,0,837,838,5,107,0,0,838,839,5,110,0,0,839,840,5,
111,0,0,840,841,5,119,0,0,841,842,5,110,0,0,842,180,1,0,0,0,843,844,5,110,
0,0,844,845,5,117,0,0,845,846,5,108,0,0,846,847,5,108,0,0,847,182,1,0,0,
0,848,849,5,93,0,0,849,184,1,0,0,0,850,851,5,91,0,0,851,186,1,0,0,0,852,
853,5,62,0,0,853,188,1,0,0,0,854,855,5,60,0,0,855,190,1,0,0,0,856,857,5,
41,0,0,857,192,1,0,0,0,858,859,5,40,0,0,859,194,1,0,0,0,860,861,5,125,0,
0,861,196,1,0,0,0,862,863,5,123,0,0,863,198,1,0,0,0,864,865,5,34,0,0,865,
200,1,0,0,0,866,867,5,96,0,0,867,202,1,0,0,0,868,869,5,58,0,0,869,204,1,
0,0,0,870,871,5,59,0,0,871,206,1,0,0,0,872,873,5,44,0,0,873,208,1,0,0,0,
874,875,5,63,0,0,875,210,1,0,0,0,876,877,5,46,0,0,877,212,1,0,0,0,878,879,
5,61,0,0,879,214,1,0,0,0,880,881,5,43,0,0,881,882,5,61,0,0,882,216,1,0,0,
0,883,884,5,45,0,0,884,885,5,61,0,0,885,218,1,0,0,0,886,887,5,61,0,0,887,
888,5,61,0,0,888,220,1,0,0,0,889,890,5,33,0,0,890,891,5,61,0,0,891,222,1,
0,0,0,892,893,5,62,0,0,893,894,5,61,0,0,894,224,1,0,0,0,895,896,5,60,0,0,
896,897,5,61,0,0,897,226,1,0,0,0,898,899,5,124,0,0,899,903,5,124,0,0,900,
901,5,111,0,0,901,903,5,114,0,0,902,898,1,0,0,0,902,900,1,0,0,0,903,228,
1,0,0,0,904,905,5,124,0,0,905,230,1,0,0,0,906,907,5,38,0,0,907,912,5,38,
0,0,908,909,5,97,0,0,909,910,5,110,0,0,910,912,5,100,0,0,911,906,1,0,0,0,
911,908,1,0,0,0,912,232,1,0,0,0,913,916,5,33,0,0,914,916,3,105,52,0,915,
913,1,0,0,0,915,914,1,0,0,0,916,234,1,0,0,0,917,919,5,64,0,0,918,917,1,0,
0,0,918,919,1,0,0,0,919,920,1,0,0,0,920,924,3,239,119,0,921,923,3,241,120,
0,922,921,1,0,0,0,923,926,1,0,0,0,924,922,1,0,0,0,924,925,1,0,0,0,925,939,
1,0,0,0,926,924,1,0,0,0,927,929,5,64,0,0,928,927,1,0,0,0,928,929,1,0,0,0,
929,930,1,0,0,0,930,931,3,237,118,0,931,935,3,239,119,0,932,934,3,241,120,
0,933,932,1,0,0,0,934,937,1,0,0,0,935,933,1,0,0,0,935,936,1,0,0,0,936,939,
1,0,0,0,937,935,1,0,0,0,938,918,1,0,0,0,938,928,1,0,0,0,939,236,1,0,0,0,
940,941,5,95,0,0,941,238,1,0,0,0,942,943,7,7,0,0,943,240,1,0,0,0,944,947,
3,239,119,0,945,947,3,237,118,0,946,944,1,0,0,0,946,945,1,0,0,0,947,242,
1,0,0,0,948,949,5,47,0,0,949,950,5,47,0,0,950,954,1,0,0,0,951,953,8,8,0,
0,952,951,1,0,0,0,953,956,1,0,0,0,954,952,1,0,0,0,954,955,1,0,0,0,955,957,
1,0,0,0,956,954,1,0,0,0,957,958,6,121,0,0,958,244,1,0,0,0,959,960,5,47,0,
0,960,961,5,42,0,0,961,962,5,42,0,0,962,966,1,0,0,0,963,965,9,0,0,0,964,
963,1,0,0,0,965,968,1,0,0,0,966,967,1,0,0,0,966,964,1,0,0,0,967,969,1,0,
0,0,968,966,1,0,0,0,969,970,5,42,0,0,970,971,5,47,0,0,971,972,1,0,0,0,972,
973,6,122,1,0,973,246,1,0,0,0,974,975,5,47,0,0,975,976,5,42,0,0,976,980,
1,0,0,0,977,979,9,0,0,0,978,977,1,0,0,0,979,982,1,0,0,0,980,981,1,0,0,0,
980,978,1,0,0,0,981,983,1,0,0,0,982,980,1,0,0,0,983,984,5,42,0,0,984,985,
5,47,0,0,985,986,1,0,0,0,986,987,6,123,0,0,987,248,1,0,0,0,988,990,7,9,0,
0,989,988,1,0,0,0,990,991,1,0,0,0,991,989,1,0,0,0,991,992,1,0,0,0,992,993,
1,0,0,0,993,994,6,124,2,0,994,250,1,0,0,0,31,0,299,308,313,317,321,743,748,
757,761,767,770,775,782,785,789,794,805,902,911,915,918,924,928,935,938,
946,954,966,980,991,3,0,1,0,0,2,0,6,0,0];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class graphLexer extends antlr4.Lexer {

    static grammarFileName = "graph.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE" ];
	static literalNames = [ null, "'from'", "'starting'", "'exiting'", "'opened'", 
                         "'blockMessage'", null, "'+'", "'-'", "'*'", "'/'", 
                         "'%'", "'apply'", "'with'", "'as'", "'async'", 
                         "'block'", "'blockcall'", "'catch'", "'comment'", 
                         "'conditions'", "'confident'", "'context'", "'cow'", 
                         "'default'", "'defined'", "'digression'", "'digressions'", 
                         "'disable'", "'do'", "'done'", "'else'", "'enable'", 
                         "'external'", "'exit'", "'finally'", "'for'", "'function'", 
                         "'global'", "'goto'", "'if'", "'import'", "'in'", 
                         "'input'", "'is'", "'library'", "'logexpr'", "'node'", 
                         "'not'", "'of'", "'on'", "'ondigreturn'", "'onexit'", 
                         "'output'", "'preprocessor'", "'priority'", "'return'", 
                         "'save'", "'set'", "'shared'", "'start'", "'static'", 
                         "'sync'", "'system'", "'timeout'", "'transitions'", 
                         "'tags'", "'try'", "'type'", "'var'", "'wait'", 
                         "'when'", "'fill'", "'#'", "'$'", null, null, null, 
                         "'empty'", "'number'", "'boolean'", "'string'", 
                         "'unknown'", "'null'", "']'", "'['", "'>'", "'<'", 
                         "')'", "'('", "'}'", "'{'", "'\"'", "'`'", "':'", 
                         "';'", "','", "'?'", "'.'", "'='", "'+='", "'-='", 
                         "'=='", "'!='", "'>='", "'<='", null, "'|'" ];
	static symbolicNames = [ null, null, null, null, null, null, "StringLiteral", 
                          "PLUS", "MINUS", "STAR", "SLASH", "PERCENT", "APPLY", 
                          "WITH", "AS", "ASYNC", "BLOCK", "BLOCKCALL", "CATCH", 
                          "COMMENT", "CONDITIONS", "CONFIDENT", "CONTEXT", 
                          "COW", "DEFAULT", "DEFINED", "DIGRESSION", "DIGRESSIONS", 
                          "DISABLE", "DO", "DONE", "ELSE", "ENABLE", "EXTERNAL", 
                          "EXIT", "FINALLY", "FOR", "FUNCTION", "GLOBAL", 
                          "GOTO", "IF", "IMPORT", "IN", "INPUT", "IS", "LIBRARY", 
                          "LOGEXPR", "NODE", "NOT", "OF", "ON", "ONDIGRETURN", 
                          "ONEXIT", "OUTPUT", "PREPROCESSOR", "PRIORITY", 
                          "RETURN", "SAVE", "SET", "SHARED", "START", "STATIC", 
                          "SYNC", "SYSTEM", "TIMEOUT", "TRANSITIONS", "TAGS", 
                          "TRY", "TYPE", "VAR", "WAIT", "WHEN", "FILL", 
                          "BuiltinMark", "ContextMark", "DecimalLiteral", 
                          "DecimalIntegerLiteral", "BooleanLiteral", "Empty", 
                          "Number", "Boolean", "String", "Unknown", "NULL", 
                          "RBRACKET", "LBRACKET", "RANGLE", "LANGLE", "RPAREN", 
                          "LPAREN", "RCURL", "LCURL", "QUOTE", "BACKTRICK", 
                          "COLON", "SEMICOLON", "COMMA", "QMARK", "PERIOD", 
                          "EQUALSIGN", "AddOp", "RemoveOp", "EqualOp", "NotEqualOp", 
                          "GreaterEqualOp", "LessEqualOp", "OrOp", "TypeOr", 
                          "AndOp", "NotOperator", "ID", "LineComment", "DocsComment", 
                          "BlockComment", "Whitespace" ];
	static ruleNames = [ "T__0", "T__1", "T__2", "T__3", "T__4", "SafeCodePoint", 
                      "StringLiteral", "StringCharacter", "MultilineStringCharacter", 
                      "MultilineEscapeSequence", "EscapeSequence", "PLUS", 
                      "MINUS", "STAR", "SLASH", "PERCENT", "APPLY", "WITH", 
                      "AS", "ASYNC", "BLOCK", "BLOCKCALL", "CATCH", "COMMENT", 
                      "CONDITIONS", "CONFIDENT", "CONTEXT", "COW", "DEFAULT", 
                      "DEFINED", "DIGRESSION", "DIGRESSIONS", "DISABLE", 
                      "DO", "DONE", "ELSE", "ENABLE", "EXTERNAL", "EXIT", 
                      "FINALLY", "FOR", "FUNCTION", "GLOBAL", "GOTO", "IF", 
                      "IMPORT", "IN", "INPUT", "IS", "LIBRARY", "LOGEXPR", 
                      "NODE", "NOT", "OF", "ON", "ONDIGRETURN", "ONEXIT", 
                      "OUTPUT", "PREPROCESSOR", "PRIORITY", "RETURN", "SAVE", 
                      "SET", "SHARED", "START", "STATIC", "SYNC", "SYSTEM", 
                      "TIMEOUT", "TRANSITIONS", "TAGS", "TRY", "TYPE", "VAR", 
                      "WAIT", "WHEN", "FILL", "BuiltinMark", "ContextMark", 
                      "DecimalLiteral", "DecimalIntegerLiteral", "DecimalLiteralAbsoluteValue", 
                      "DecimalIntegerLiteralAbsoluteValue", "ExponentPart", 
                      "BooleanLiteral", "Empty", "Number", "Boolean", "String", 
                      "Unknown", "NULL", "RBRACKET", "LBRACKET", "RANGLE", 
                      "LANGLE", "RPAREN", "LPAREN", "RCURL", "LCURL", "QUOTE", 
                      "BACKTRICK", "COLON", "SEMICOLON", "COMMA", "QMARK", 
                      "PERIOD", "EQUALSIGN", "AddOp", "RemoveOp", "EqualOp", 
                      "NotEqualOp", "GreaterEqualOp", "LessEqualOp", "OrOp", 
                      "TypeOr", "AndOp", "NotOperator", "ID", "Underscore", 
                      "NonUnderscoreIdSymbol", "AnyIdSymbol", "LineComment", 
                      "DocsComment", "BlockComment", "Whitespace" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.atn.PredictionContextCache());
    }
}

graphLexer.EOF = antlr4.Token.EOF;
graphLexer.T__0 = 1;
graphLexer.T__1 = 2;
graphLexer.T__2 = 3;
graphLexer.T__3 = 4;
graphLexer.T__4 = 5;
graphLexer.StringLiteral = 6;
graphLexer.PLUS = 7;
graphLexer.MINUS = 8;
graphLexer.STAR = 9;
graphLexer.SLASH = 10;
graphLexer.PERCENT = 11;
graphLexer.APPLY = 12;
graphLexer.WITH = 13;
graphLexer.AS = 14;
graphLexer.ASYNC = 15;
graphLexer.BLOCK = 16;
graphLexer.BLOCKCALL = 17;
graphLexer.CATCH = 18;
graphLexer.COMMENT = 19;
graphLexer.CONDITIONS = 20;
graphLexer.CONFIDENT = 21;
graphLexer.CONTEXT = 22;
graphLexer.COW = 23;
graphLexer.DEFAULT = 24;
graphLexer.DEFINED = 25;
graphLexer.DIGRESSION = 26;
graphLexer.DIGRESSIONS = 27;
graphLexer.DISABLE = 28;
graphLexer.DO = 29;
graphLexer.DONE = 30;
graphLexer.ELSE = 31;
graphLexer.ENABLE = 32;
graphLexer.EXTERNAL = 33;
graphLexer.EXIT = 34;
graphLexer.FINALLY = 35;
graphLexer.FOR = 36;
graphLexer.FUNCTION = 37;
graphLexer.GLOBAL = 38;
graphLexer.GOTO = 39;
graphLexer.IF = 40;
graphLexer.IMPORT = 41;
graphLexer.IN = 42;
graphLexer.INPUT = 43;
graphLexer.IS = 44;
graphLexer.LIBRARY = 45;
graphLexer.LOGEXPR = 46;
graphLexer.NODE = 47;
graphLexer.NOT = 48;
graphLexer.OF = 49;
graphLexer.ON = 50;
graphLexer.ONDIGRETURN = 51;
graphLexer.ONEXIT = 52;
graphLexer.OUTPUT = 53;
graphLexer.PREPROCESSOR = 54;
graphLexer.PRIORITY = 55;
graphLexer.RETURN = 56;
graphLexer.SAVE = 57;
graphLexer.SET = 58;
graphLexer.SHARED = 59;
graphLexer.START = 60;
graphLexer.STATIC = 61;
graphLexer.SYNC = 62;
graphLexer.SYSTEM = 63;
graphLexer.TIMEOUT = 64;
graphLexer.TRANSITIONS = 65;
graphLexer.TAGS = 66;
graphLexer.TRY = 67;
graphLexer.TYPE = 68;
graphLexer.VAR = 69;
graphLexer.WAIT = 70;
graphLexer.WHEN = 71;
graphLexer.FILL = 72;
graphLexer.BuiltinMark = 73;
graphLexer.ContextMark = 74;
graphLexer.DecimalLiteral = 75;
graphLexer.DecimalIntegerLiteral = 76;
graphLexer.BooleanLiteral = 77;
graphLexer.Empty = 78;
graphLexer.Number = 79;
graphLexer.Boolean = 80;
graphLexer.String = 81;
graphLexer.Unknown = 82;
graphLexer.NULL = 83;
graphLexer.RBRACKET = 84;
graphLexer.LBRACKET = 85;
graphLexer.RANGLE = 86;
graphLexer.LANGLE = 87;
graphLexer.RPAREN = 88;
graphLexer.LPAREN = 89;
graphLexer.RCURL = 90;
graphLexer.LCURL = 91;
graphLexer.QUOTE = 92;
graphLexer.BACKTRICK = 93;
graphLexer.COLON = 94;
graphLexer.SEMICOLON = 95;
graphLexer.COMMA = 96;
graphLexer.QMARK = 97;
graphLexer.PERIOD = 98;
graphLexer.EQUALSIGN = 99;
graphLexer.AddOp = 100;
graphLexer.RemoveOp = 101;
graphLexer.EqualOp = 102;
graphLexer.NotEqualOp = 103;
graphLexer.GreaterEqualOp = 104;
graphLexer.LessEqualOp = 105;
graphLexer.OrOp = 106;
graphLexer.TypeOr = 107;
graphLexer.AndOp = 108;
graphLexer.NotOperator = 109;
graphLexer.ID = 110;
graphLexer.LineComment = 111;
graphLexer.DocsComment = 112;
graphLexer.BlockComment = 113;
graphLexer.Whitespace = 114;



